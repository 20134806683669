import { Link } from 'gatsby'
import { darken, getLuminance, lighten } from 'polished'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ bgColor: string, color: string }>`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};

  padding: 10px 0;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 10px;
  > :first-child {
    font-family: "Rammetto One";
    > :first-child {
      font-weight: normal !important;
    }
  }
`

const Item = styled.div<{ selected: boolean, color: string }>`
  position: relative;
  padding: 5px 20px;
  ${({ selected, color }) =>
      selected &&
      css`
        font-weight: bold;
        ::after {
          border-bottom: 1px solid ${color};
        }
      `}
  ::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
  }
`

interface IProps {
  items: {
    title: string,
    slug: string,
    to: string,
  }[],
  headerColor: string
  selected: string
}

const Header = (props: IProps) => {

  const { bgColor, color } = useMemo(() => {
    try {
      const bgColor = getLuminance(props.headerColor) < 0.5 ? darken(0.2, props.headerColor) : lighten(0.2, props.headerColor)
      const color = getLuminance(bgColor) > 0.5 ? '#000' : '#fff'
      return { bgColor, color }
    } catch (err) {
      return {
        bgColor: '#ebebeb',
        color: '#000'
      }
    }
  }, [])

  return <Wrapper bgColor={bgColor} color={color}>
    <Items>
      {
        props.items.map(item =>
          <Link key={item.slug} to={item.to}>
            <Item className='nav-item' selected={item.slug === props.selected} color={color}>
              { item.title }
            </Item>
          </Link>)
      }
    </Items>
  </Wrapper>
}

export default Header
